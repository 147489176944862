
import { defineComponent } from 'vue';
import WebcamVideo from './components/WebcamVideo.vue';
import Meteo from './components/meteo/Meteo.vue';
import { MeteoClass } from '@/models/meteo.models';
import { FaceExpressions, FaceExpressionResult } from '@/models/face-api.models';

export default defineComponent({
  name: 'App',
  components: {
    WebcamVideo,
    Meteo
  },
  data() {
    return {
      meteoClass: 'cloudy' as MeteoClass,
      expression: 'neutral' as FaceExpressions,
      percentage: 0,
    };
  },
  methods: {
    onFaceExpressionChange(expression: FaceExpressionResult) {
      console.debug(expression);
      if(!expression) {
        return;
      }

      this.expression = expression.name;
      this.percentage = expression.percentage;

      switch(expression.name) {
        case 'happy':
          this.meteoClass = 'sun';
          break;
        case 'angry':
          this.meteoClass = 'storm';
          break;
        case 'sad':
          this.meteoClass = 'rain';
          break;
        case 'disgusted':
          this.meteoClass = 'pollution';
          break;
        case 'surprised':
          this.meteoClass = 'sunset';
          break;
        case 'fearful':
          this.meteoClass = 'fearful';
          break;
        case 'neutral':
        default:
          this.meteoClass = 'cloudy';
      }
    }
  },
  computed: {}
});
