
import { defineComponent } from 'vue';
import { draw } from 'face-api.js';
import FaceApiService from '@/services/FaceApiService';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { DetectionResult, FaceExpressionResult } from '@/models/face-api.models';


export default defineComponent({
  name: 'WebcamVideo',
  data() {
    return {
      isButtonsVisible: this.showButtons,
      isVideoVisible: this.showVideo,
      isLandmarksVisible: this.showLandmarks,
      isPlaying: true,
      width: this.maxVideoWidth,
      height: this.maxVideoHeight,
      expression: null as FaceExpressionResult | null
    };
  },
  props: {
    maxVideoWidth: { type: Number, default: 600 },
    maxVideoHeight: { type: Number, default: 600 },
    percentageThreshold: { type: Number, default: 0.9 },
    showVideo: {
      type: Boolean,
      default: true
    },
    showButtons: {
      type: Boolean,
      default: false
    },
    showLandmarks: {
      type: Boolean,
      default: false
    }
  },
  mounted: async function() {
    await this.play();
  },
  computed: {
    styleObject(): { width: string, height: string, opacity: string } {
      return {
        width: `${ this.width }px`,
        height: `${ this.height }px`,
        opacity: this.isVideoVisible ? '1' : '0'
      };
    }
  },
  methods: {
    togglePlayVideo: function() {
      this.isPlaying ? this.stop() : this.play();
    },
    toggleShowButtons: function() {
      this.isButtonsVisible = !this.isButtonsVisible;
    },
    toggleShowVideo: function() {
      this.isVideoVisible = !this.isVideoVisible;
    },
    toggleShowLandmarks: function() {
      this.isLandmarksVisible = !this.isLandmarksVisible;
      if(!this.isLandmarksVisible) {
        this.clearCanvas();
      }
    },
    play: async function() {
      console.debug('Start playing...');
      this.isPlaying = true;
      const videoRef = this.$refs.video as HTMLVideoElement;
      const canvasRef = this.$refs.canvas as HTMLCanvasElement;
      await FaceApiService.init();

      videoRef.srcObject = await FaceApiService.getVideo({
        maxWidth: this.maxVideoWidth,
        maxHeight: this.maxVideoHeight
      });

      const videoSettings = videoRef.srcObject?.getVideoTracks()[0]?.getSettings();

      this.width = videoSettings?.width || this.maxVideoWidth;
      this.height = videoSettings?.height || this.maxVideoHeight;

      const refreshMs = 100;
      const debounceMs = 350;

      // Try to detect expression every tot time
      const expressionDetection$ = FaceApiService.getExpressionDetection({ videoRef, refreshMs });

      // Print on canvas
      expressionDetection$.pipe(
          filter(() => !!this.isLandmarksVisible),
          tap(detection => this.printLandmarks(detection, canvasRef))
      ).subscribe();


      // Manage expression
      expressionDetection$.pipe(
          filter(detection => !!detection),
          map((detection: DetectionResult) => FaceApiService.getExpression(detection, this.percentageThreshold)),
          filter(detection => !!detection),
          distinctUntilChanged((previous: FaceExpressionResult, current: FaceExpressionResult) => previous?.name === current?.name),
          debounceTime(debounceMs),
      ).subscribe(expression => this.expressionChanged(expression));
      console.debug('Started');
    },
    stop: async function() {
      console.debug('stop');
      const videoRef = this.$refs.video as HTMLVideoElement;
      videoRef.srcObject = null;
      this.isPlaying = false;
    },
    clearCanvas: function() {
      const canvas = this.$refs.canvas as HTMLCanvasElement;
      const context = canvas?.getContext('2d');
      context?.clearRect(0, 0, canvas?.width, canvas?.height);
    },
    printLandmarks: async function(detection: DetectionResult, canvasRef: HTMLCanvasElement) {
      this.clearCanvas();
      if(detection) {
        draw.drawDetections(canvasRef, detection);
      }
    },
    expressionChanged(expression: FaceExpressionResult) {
      this.expression = expression;
      this.$emit('expressionChanged', expression);
    }
  },
  emits: ['expressionChanged']
});
